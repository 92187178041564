<template>
  <div>
      <TopNavbar />
      <div class="about" id="about">
      <h1>ABOUT US</h1>
      <p class="description">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
        Lorem Ipsum has been the industry's standard dummy text ever since the
        1500s, when an unknown printer took a galley of type and scrambled it to
        make a type specimen book.
      </p>
      <div class="services">
        <div
          class="services__service"
          v-for="service in services"
          :key="service.id"
        >
          <img v-bind:src="service.imageUrl" alt="" />
          <h3>{{ service.name }}</h3>
          <p>
            {{ service.description }}
          </p>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  
</template>

<script>
import TopNavbar from "@/components/common/TopNavbar.vue";
import Footer from "@/components/common/Footer.vue";

export default {
  name: "About",
  components: { TopNavbar, Footer },
  data: () => ({
    services: [
      {
        name: "Service 1",
        imageUrl:
          "https://cdn.pixabay.com/photo/2015/09/16/20/10/dough-943245_960_720.jpg",
        description:
          " Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        name: "Service 2",
        imageUrl:
          "https://cdn.pixabay.com/photo/2016/08/11/08/04/vegetables-1584999_960_720.jpg",
        description:
          " Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        name: "Service 3",
        imageUrl:
          "https://cdn.pixabay.com/photo/2016/02/19/10/18/chef-1209161_960_720.jpg",
        description:
          " Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
    ],
  }),
};
</script>

<style lang="scss">
@import "../components/common/css/global.scss";
@import "../components/common/css/common.scss";

p {
  font-size: 18px;
}

.about {
  background-color: $white5;
  text-align: center;
  padding: 50px;
  padding-top: 140px;
  padding-bottom: 120px;

  & h1 {
    font-size: 35px;
  }

  @media (max-width: $bp-mobile) {
    padding: 50px 14px 10px;
  }
}

.description {
  margin-top: 20px;
  margin-bottom: 30px;
  width: 80%;
  max-width: 900px;
  display: inline-block;
}

.services {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  & .services__service {
    margin: 50px;
    max-width: 300px;
    @media (max-width: $bp-mobile) {
      margin: 40px 10px 10px;
    }

    & img {
      margin-bottom: 20px;
      width: 250px;
      height: 250px;
      object-fit: cover;
    }

    & h3 {
      font-size: 22px;
    }
  }
}
</style>
